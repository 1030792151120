<template>
  <div class="yx_7525">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="step_1">
      <div class="bgtime">
        <div class="jytime">
          <p>议息活动交易时间：2024年12月16日~12月21日</p>
          <p>下次议息公布时间：北京时间2024年12月19日03:00</p>
        </div>
      </div>
      <div class="btn-time">
        <div style="position: relative">
          <img class="slider" src="/subject/yx_1105/wap/slider.png" alt="" />

          <span class="day">{{ day }}</span>
          <span class="hours">{{ hour }}</span>
          <span class="minute">{{ min }}</span>
          <span class="second">{{ second }}</span>
        </div>
      </div>

      <!-- <div class="w"> -->

      <!-- <div class="text">
                    <p>听公开课 盈利更有把握</p>
                    <p>公开课时间： 7月26日20:00-22:00</p>
                </div> -->
      <!-- </div> -->
    </div>
    <div class="w1">
      <div class="step_2">
        <div class="teacherlist" v-if="teacherList.length > 0">
          <div class="teacher" v-for="(ite, index) in teacherList" :key="index">
            <a class="toHref" :href="ite.url"></a>
            <img style="width: 100%; height: 100%" :src="ite.imgUrl" alt="" />
          </div>
        </div>
        <!-- <img class="teacher" src="/subject/yx_1105/wap/teacher.png" alt="" /> -->
        <img
          class="step-title"
          style="margin-bottom: 23px"
          src="/subject/yx_1105/wap/title.png"
          alt=""
        />
        <div class="flex">
          <div style="position: relative;margin: 24px 0"  @mouseenter="hoverLive()"
              @mouseleave="hoverLive3">
            <div class="text-content">
              <div class="text-cont">
                <div class="commen-tit">聚焦美联储议息</div>
                <div class="commen-active"></div>
                <div class="commen-cont">
                  美联储议息会议是(FOMC)，主要内容是透过调控当下的货币政策，来达到经济成长及物价稳定两者间的平衡...
                </div>
              </div>
            </div>
            <div class="text-content1" v-show="showcont">
                <div class="text-cont">
                  <div class="commen-tit">聚焦美联储议息</div>
                  <div class="active" style="color:#fff"></div>
                  <div class="commen-cont" >
                    <p>
                      美联储议息会议是(FOMC)，主要内容是透过调控当下的货币政策，来达到经济成长及物价稳定两者间的平衡。
                    </p>
                    <p>
                      11月议息上，美联储选择继续降息25个基点，将联邦利率有效区间下修在4.5%-4.75%，符合市场预期。尽管面对经济、就业和通胀数据反弹，美联储仍坚持降息。本次议息看点将围绕在美联储是否会继续降息，在当前经济数据回稳物价数据下滑停滞的背景下，美联储是否会提早结束降息循环，此外市场也将聚焦在最新的经济预测摘要(SEP)。
                    </p>
                  </div>
                </div>
              </div>
          <img class="background" src="/subject/yx_1105/wap/bg10.png" alt="" />

          </div>

         
           <div style="position: relative;margin: 24px 0"  @mouseenter="hoverLive1()"
              @mouseleave="hoverLive4">
            <div class="text-content">
              <div class="text-cont">
                <div class="commen-tit">如何引爆交易机会?</div>
                <div class="commen-active"></div>
                <div class="commen-cont">
                 行情动荡引发交易机会美元主导着全球金融交易市场，议息结果公 布后，引发期货，股市，债市波动...
                </div>
              </div>
            </div>
              <div class="text-content1" v-show="showcont1">
                <div class="text-cont">
                  <div class="commen-tit">如何引爆交易机会</div>
                  <div class="active" style="color:#fff"></div>
                  <div class="commen-cont" >
                     <p>行情动荡引发交易机会</p>
                    <p>
                      美元主导着全球金融交易市场，议息结果公布后，引发期货，股市，债市波动，给投资者创造了良好交易的机会，投资者可以根据加息减息来判断市场美元流动性，有迹可循的进行投资交易。
                    </p>
                    <p>
                      本次最大看点在美联储接下来的降息动向，随着美国经济数据近期逐渐有放缓的迹象，预料美联储将持续性降息以为经济进行托底，避免经济衰退才能达成软着陆。
                    </p>
                  </div>
                </div>
              </div>
          <img class="background" src="/subject/yx_1105/wap/bg8.png" alt="" />

          </div>
          <div style="position: relative"  @mouseenter="hoverLive2()"
              @mouseleave="hoverLive5">
            <div class="text-content">
              <div class="text-cont">
                <div class="commen-tit">选择什么交易产品?</div>
                <div class="commen-active"></div>
                <div class="commen-cont">
                  用美元定价和结算的产品首先，议息会议决定了美元的升值或贬值， 这直接反应了与美元相对应货币的价值...
                </div>
              </div>
            </div>
              <div class="text-content1" v-show="showcont2">
                <div class="text-cont">
                  <div class="commen-tit">选择什么交易产品?</div>
                  <div class="active" style="color:#fff"></div>
                  <div class="commen-cont" >
                    <p>
                       用美元定价和结算的产品首先，议息会议决定了美元的升值或贬值，这直接反应了与美元相对应货币的价值波动。例如：美元/日元，美元加息，美元流动性减少，美元相对于日元升值，日元贬值。
                    </p>
                    
                  </div>
                </div>
              </div>
          <img class="background" src="/subject/yx_1105/wap/bg9.png" alt="" />

          </div>
        </div>
      </div>
    </div>
    <div class="w2">
      <div class="step_3">
        <img
          class="step-title"
          style="margin-bottom: 23px; margin-left: 35%"
          src="/subject/yx_1105/wap/title1.png"
          alt=""
        />

        <div class="imgBox">
          <img src="/subject/yx_1105/wap/bg5.png" alt="" />
          <div class="flex1">
            <img src="/subject/yx_1105/wap/bg6.png" alt="" />
            <img src="/subject/yx_1105/wap/bg7.png" alt="" />
          </div>
        </div>

        <div class="btnBox">
          <p><span @click="showDialog = true">活动规则 ></span></p>

          <div class="flex1">
            <img
              @click="scrollToTarget"
              style="margin-right: 14px"
              src="/subject/yx_1105/wap/deal.png"
              alt=""
            />

            <img
              @click="getLive800"
              src="/subject/yx_1105/wap/request.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w3">
      <div class="step_4">
        <img
          class="step-title"
          style="margin-bottom: 23px"
          src="/subject/yx_1105/wap/title2.png"
          alt=""
        />

        <div class="title">
          <p>
            <span>当前议息政策:</span>
            北京时间2024年12月19日03:00，美联储料将继上个月降息后再次降息。据CME美联储观察，美联储12月降息25个基点的概率为86.1%，停止降息的概率为1%。随着近期美国经济数据维持强劲与物价数据下滑停滞，市场定调美联储降息循环空间可能所剩不多，明年可能再降息两次，最终政策利率将落在3.75-4.00%，相较于之前市场过度乐观至2%开头有所收殓，此外美联储也将在这次议息上公布最新经济预测摘要(SEP)。
          </p>
         
        </div>
        <div style="
         margin-top: 20px">
          <!-- 表格 -->
          <div class="tabs">
            <table style="border-collapse:collapse;">
              <tr class="biaoti">
                <th>央行</th>
                <th>当前利率</th>
                <th>下次预测</th>
                <th>最近加降息(非0基点)</th>
                <th>历史峰值</th>
                <th>历史最低</th>
                <th>CPI最新值</th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi01.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">美联储FED</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">4.5-4.75</p>
                  <p class="times">2024-11-07</p>
                </th>
                <th>
                  <p class="numbers actives">4.25-4.50</p>
                  <p class="times">2024-12-18</p>
                </th>
                <th>
                  <p class="numbers">-25</p>
                  <p class="times">2023-11-07</p>
                </th>
                <th>
                  <p class="numbers actives">20</p>
                  <p class="times">1980-03-04</p>
                </th>
                <th>
                  <p class="numbers">0-0.25</p>
                  <p class="times">2008-12-27</p>
                </th>
                <th>
                  <p class="numbers">2.6</p>
                </th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi02.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">欧洲央行ECB</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">3.4</p>
                  <p class="times">2024-10-17</p>
                </th>
                <th>
                  <p class="numbers actives">3.15</p>
                  <p class="times">2024-12-12</p>
                </th>
                <th>
                  <p class="numbers">-25</p>
                  <p class="times">2024-10-17</p>
                </th>
                <th>
                  <p class="numbers actives">4.75</p>
                  <p class="times">2000-10-05</p>
                </th>
                <th>
                  <p class="numbers">0.00</p>
                  <p class="times">2016-03-16</p>
                </th>
                <th>
                  <p class="numbers">2.3</p>
                </th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi03.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">日本央行BOJ</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">0.25</p>
                  <p class="times">2024-10-31</p>
                </th>
                <th>
                  <p class="numbers actives">0.25</p>
                  <p class="times">2024-12-19</p>
                </th>
                <th>
                  <p class="numbers">15</p>
                  <p class="times">2024-07-31</p>
                </th>
                <th>
                  <p class="numbers actives">0.30</p>
                  <p class="times">2008-10-31</p>
                </th>
                <th>
                  <p class="numbers">-0.10</p>
                  <p class="times">2016-01-29</p>
                </th>
                <th>
                  <p class="numbers">2.3</p>
                </th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi04.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">英国央行BOE</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">4.75</p>
                  <p class="times">2024-11-07</p>
                </th>
                <th>
                  <p class="numbers actives">4.75</p>
                  <p class="times">2024-12-19</p>
                </th>
                <th>
                  <p class="numbers">-25</p>
                  <p class="times">2024-11-07</p>
                </th>
                <th>
                  <p class="numbers actives">17</p>
                  <p class="times">1979-11-15</p>
                </th>
                <th>
                  <p class="numbers">0.1</p>
                  <p class="times">2020-03-26</p>
                </th>
                <th>
                  <p class="numbers">2.3</p>
                </th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi05.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">瑞士央行SNB</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">1</p>
                  <p class="times">2024-09-26</p>
                </th>
                <th>
                  <p class="numbers actives">0.75</p>
                  <p class="times">2024-12-12</p>
                </th>
                <th>
                  <p class="numbers">-25</p>
                  <p class="times">2024-09-26</p>
                </th>
                <th>
                  <p class="numbers actives">3.5</p>
                  <p class="times">2000-02-03</p>
                </th>
                <th>
                  <p class="numbers">-0.75</p>
                  <p class="times">2015-01-15</p>
                </th>
                <th>
                  <p class="numbers">0.7</p>
                </th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi06.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">澳洲联储RBA</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">4.35</p>
                  <p class="times">2024-12-10</p>
                </th>
                <th>
                  <p class="numbers actives">4.10</p>
                  <p class="times">2025-02-04</p>
                </th>
                <th>
                  <p class="numbers">+25</p>
                  <p class="times">2023-11-07</p>
                </th>
                <th>
                  <p class="numbers actives">17.00</p>
                  <p class="times">1990-01-22</p>
                </th>
                <th>
                  <p class="numbers">0.75</p>
                  <p class="times">2019-10-01</p>
                </th>
                <th>
                  <p class="numbers">2.8</p>
                </th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi07.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">加拿大央行BOC</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">3.75</p>
                  <p class="times">2024-10-23</p>
                </th>
                <th>
                  <p class="numbers actives">3.25</p>
                  <p class="times">2024-12-11</p>
                </th>
                <th>
                  <p class="numbers">-25</p>
                  <p class="times">2024-10-23</p>
                </th>
                <th>
                  <p class="numbers actives">8.06</p>
                  <p class="times">1995-02-23</p>
                </th>
                <th>
                  <p class="numbers">0.25</p>
                  <p class="times">2009-04-21</p>
                </th>
                <th>
                  <p class="numbers">2</p>
                </th>
              </tr>
              <tr class="bodys">
                <th>
                  <div class="guoqi">
                    <img src="/subject/yx_7525/index/qi08.png" alt="" />
                    <div class="guojia">
                      <p class="guoji">新西兰联储RBNZ</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p class="numbers">4.25</p>
                  <p class="times">2024-11-27</p>
                </th>
                <th>
                  <p class="numbers actives">4.00</p>
                  <p class="times">2025-02-19</p>
                </th>
                <th>
                  <p class="numbers">-50</p>
                  <p class="times">2024-11-27</p>
                </th>
                <th>
                  <p class="numbers actives">8.25</p>
                  <p class="times">2007-07-26</p>
                </th>
                <th>
                  <p class="numbers">0.25</p>
                  <p class="times">2020-03-16</p>
                </th>
                <th>
                  <p class="numbers">2.2</p>
                </th>
              </tr>
            </table>
            
          </div>
          <div class="btnBox">
              <img
                @click="scrollToTarget"
                style="margin-right:15px"
                src="/subject/yx_1105/button2.png"
                alt=""
              />
              <img
                @click="getLive800"
                src="/subject/yx_1105/button.png"
                alt=""
              />
              <img
                src="/subject/yx_7525/index/ewm.png"
                class="ewm"
                @click="ewm33"
                v-if="ewm3"
                alt=""
              />
            </div>
        </div>
      </div>
      <div class="step_4">
        <img
          class="step-title"
          style="margin-bottom: 23px"
          src="/subject/yx_1105/wap/title3.png"
          alt=""
        />
        <div style="position: relative">
          <img
            class="background3"
            src="/subject/yx_1105/wap/background3.png"
            alt=""
          />
        </div>
        <div class="text-box">
          <div class="top">
            <p class="titles">新闻资讯</p>
            <p class="more" @click="selectMore">查看更多 ></p>
          </div>
          <div class="newflex">
                <div
                  class="newdate"
                  v-if="index < 4"
                  v-for="(item, index) in newData"
                  :key="index"
                >
                  <img :src="item.TitlePic" alt="" />
                  <div>
                    <p class="tits">{{ item.Synopsis }}</p>
                    <p class="times">{{ item.NewsTime.substring(0, 10) }}</p>
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
    <div class="w4" ref="targetElement">
      <div class="step_5">
        <img
          class="step-title tit"
          src="/subject/yx_1105/wap/title4.png"
          alt=""
        />

        <div class="box">
          <div class="right">
            <div class="appCode">
              <div class="codeBody">
                <div>MT4账户专用版本</div>
                <div class="codeBox">
                  <div class="codeItem">
                    <Qrcode
                      :link="iosMt4DownloadUrl"
                      :size="108"
                      :iconSize="30"
                    />
                    <div>iOS</div>
                  </div>
                  <div class="codeItem">
                    <Qrcode :link="androidMt4DownloadUrl" :size="108" />
                    <div>Android</div>
                  </div>
                </div>
              </div>
              <div class="codeBody">
                <div>MT5账户专用版本</div>
                <div class="codeBox">
                  <div class="codeItem">
                    <Qrcode :link="iosMt5DownloadUrl" :size="108" />
                    <div>iOS</div>
                  </div>
                  <div class="codeItem">
                    <Qrcode :link="androidDownloadUrl" :size="108" />
                    <div>Android</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="serveBox">
              <div class="textBox">下载官方APP，发现更多智富可能</div>

              <div class="serveBox-item">
                <div>1、</div>
                <div>MT4版本用MT4账户，MT5版本用MT5账户，两者不互通；</div>
               
              </div>
              <div class="serveBox-item">
                <div>2、</div>
                <div>本平台2024年8月31日10：00之前开立的账户，均
              为MT4账户。之后开立的账户均为MT5账户。如果
                
                  不确定，请联系<span class="serveBox-serve" @click="getLive800"
                    >在线客服</span
                  >查询。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <contactusVue v-if="!isApp"></contactusVue> -->
    <div v-if="showDialog" class="dialog">
      <img
        class="closer"
        @click="showDialog = false"
        src="/subject/feinong/wap/guanbi2.png"
        alt=""
      />
      <ul class="dialogContent">
        <li>
          <span>活动对象：</span>
          本公司所有新老用户
        </li>
        <li>
          <span>活动交易时间：</span>
          2024年12月16日06:00-2024年12月21日06:00
        </li>
        <li>
          <span>兑奖截止时间：</span>
          2025年1月25 日00:00（逾期失效）
        </li>
        <li>交易送金，升降任你赚！交易越多赚越多，最高领4000美元现金！</li>
        <li>
          <span>活动规则：</span><br />
          活动期间内，凡在本公司的APP或MT交易平台交易指定品种，完成指定交易手数，即可兑换相应美元现金奖励，交易时间结束后，统一开始兑换，限兑奖一次；
        </li>
        <li>
          <span>活动参与品种：</span><br />
          美国原油、 美元/日元、 欧元/美元、英镑/美元、 纳斯达克指数、
          标普500指数、 道琼斯指数
        </li>
        <li>
          <span>交易奖励：</span><br />
          <table>
            <tr>
              <th>交易品种</th>
              <th>赠送条件</th>
              <th>美元现金奖励</th>
            </tr>
            <tr>
              <th rowspan="11">
                <p>美国原油</p>
                <p>美元/日元</p>
                <p>欧元/美元</p>
                <p>英镑/美元</p>
                <p>纳斯达克指数</p>
                <p>标普500指数</p>
                <p>道琼斯指数</p>
              </th>
              <th>≥3手</th>
              <th>6美元</th>
            </tr>
            <tr>
              <th>≥5手</th>
              <th>12美元</th>
            </tr>
            <tr>
              <th>≥10手</th>
              <th>25美元</th>
            </tr>
            <tr>
              <th>≥20手</th>
              <th>60美元</th>
            </tr>
            <tr>
              <th>≥50手</th>
              <th>180美元</th>
            </tr>
            <tr>
              <th>≥80手</th>
              <th>300美元</th>
            </tr>
            <tr>
              <th>≥100手</th>
              <th>400美元</th>
            </tr>
            <tr>
              <th>≥150手</th>
              <th>600美元</th>
            </tr>
            <tr>
              <th>≥200手</th>
              <th>800美元</th>
            </tr>
            <tr>
              <th>≥500手</th>
              <th>2000美元</th>
            </tr>
            <tr>
              <th>≥1000手</th>
              <th>4000美元</th>
            </tr>
          </table>
        </li>
        <li>
          <span>领取方式：</span><br />
          1.议息奖励在“APP”→“我的”→“活动中心”→“议息活动”中领取； <br />
          2.活动只计算活动时间内的交易手数，奖励会在活动结束后开启兑换； <br />
          3.奖励后的美元可直接提现，亦可继续用于交易；<br />
          4.美元现金奖励需在兑奖截止日期前领取，逾期失效；
        </li>
        <li>
          <span>注意事项</span><br />
          1.客户参与活动需在本公司开立真实交易账户；<br />
          2.本活动仅限于活动指定品种的交易手数，手数计算以开平仓为准；<br />
          3.各账户层级用户均可参加本活动，美元现金奖励将依据活动交易时间结束后的最终平仓手数发放；<br />
          4.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
        </li>
      </ul>
    </div>
    <div class="dark" v-if="showDialog"></div>
  </div>
</template>
<script>
import ContactusVue from "../../Contactus.vue";
import { getYiXiTime } from "../../../../api/subject/yx_7525";
import { parseTime } from "../../../../utils/tools";
import Qrcode from "../../QrCode/index.vue";
import { mapState } from "vuex";
import { getTeacherList, getTime } from "../../../../api/subject/hdtime";
export default {
  name: "feinongWap",
  components: {
    ContactusVue,
    Qrcode,
  },
  data() {
    return {
      showDialog: false,
      navBarFixed: false,
      scroll: "",
      isApp: false,
      activityTime: {
        starttime: null,
        endtime: null,
        prizedeadline: null,
      },
      curStartTime: "2024-11-08 02:00:00",
      day: 0,
      hour: 0,
      min: 0,
      second: 0,
      timer: null,
      teacherList: [],
      form: {
        languageIdentification: "1",
        type: "62",
        brandId: "1",
        region: "1",
      },
      form1: {
        key: "YXReleaseDate",
      },
      timer: null,
      showcont: false,
      showcont1: false,
      showcont2: false,
      showDown: false,
      newData: [
        {
          ID: 6251,
          Title: "11月6日财经早餐：市场关注美国大选，无论结果如何黄",
          NewsTime: "2024-11-06 09:17:15",
          NewsContext: null,
          WeekReview: false,
          ClassID: 0,
          prveID: 0,
          nextID: 0,
          TitlePic:
            "https://xsnews.hflbysm.com/pm/net/107/20241106/97339136d8ef4e0980900ce460d67456.png",
          WriterName: "明杰",
          ViewingCount: 0,
          Synopsis:
            "美国10月ISM服务业PMI扩张速度创两年多最快，就业强劲反弹，价格指数回落。数据公布后，美债10年收益率日内涨幅达到1.02%，道琼斯工业平均指数出现上涨。",
          Source: "市场资讯",
        },
        {
          ID: 6250,
          Title: "美国10月ISM服务业PMI扩张速度创两年多最快，就",
          NewsTime: "2024-11-06 09:14:52",
          NewsContext: null,
          WeekReview: false,
          ClassID: 0,
          prveID: 0,
          nextID: 0,
          TitlePic:
            "https://xsnews.hflbysm.com/pm/net/107/20241106/9a8927e0370f471b9f4067b48677b19d.png",
          WriterName: "明杰",
          ViewingCount: 0,
          Synopsis:
            "美国10月ISM服务业PMI指数为56，创2022年7月以来新高，高于媒体调查的几乎所有经济学家的预测。",
          Source: "市场资讯",
        },
        {
          ID: 6249,
          Title: "花旗：美联储12月跳过降息的押注具有吸引力",
          NewsTime: "2024-11-06 09:12:44",
          NewsContext: null,
          WeekReview: false,
          ClassID: 0,
          prveID: 0,
          nextID: 0,
          TitlePic:
            "https://xsnews.hflbysm.com/pm/net/107/20241106/8e68f3d7ed234f608255e84e48044fa8.jpg",
          WriterName: "明杰",
          ViewingCount: 0,
          Synopsis:
            "花旗分析师贾巴兹·马塔伊表示，以目前的市场赔率来看，押注美联储不会在12月再次降息是有吸引力的。",
          Source: "港股那点事",
        },
        {
          ID: 6242,
          Title: "彭博美元指数在美国大选前一天跌超0.4%，日元涨超0",
          NewsTime: "2024-11-05 08:08:37",
          NewsContext: null,
          WeekReview: false,
          ClassID: 0,
          prveID: 0,
          nextID: 0,
          TitlePic:
            "https://xsnews.hflbysm.com/pm/net/107/20241105/377485f4fb9f4c499fef105b3e293e75.jpg",
          WriterName: "马文",
          ViewingCount: 0,
          Synopsis:
            "格隆汇11月5日｜周一（11月4日，上周五发布了美国非农就业报告）纽约尾盘，ICE美元指数下跌0.36%，报103.905点，全天处于下跌状态，日内交投区间为103.960-103.576点，最近两个交易日下探200日均线（该技术指标暂报103.839点）。彭博美元指数跌0.42%，报1258.55点，日内交投区间为1259.22-1254.85点。  美元兑日元跌0.56%，报152.15日元，",
          Source: "港股那点事",
        },
        {
          ID: 6241,
          Title: "11月5日财经早餐：黄金价格保持坚挺，交易员转为净做",
          NewsTime: "2024-11-05 08:07:00",
          NewsContext: null,
          WeekReview: false,
          ClassID: 0,
          prveID: 0,
          nextID: 0,
          TitlePic:
            "https://xsnews.hflbysm.com/pm/net/107/20241105/667f8c5aaffb4e1cb8cf5bf9a6843180.jpg",
          WriterName: "马文",
          ViewingCount: 0,
          Synopsis:
            "　　汇通财经APP讯——以下是周二 （11月5日）财经早餐，包括基本面重要消息、贵金属/原油/外汇/商品/股市/债市等行情、国际要闻、国内要闻、机构观点、今日财经重要数据及财经大事。美国9月耐用品订单终值环比下降0.7%，预估为下降0.8%。周一原油价格上涨超3%，OPEC+因需求担忧推迟增产。COT报告称，交易员转为净做空日元。伊朗将利用一切可能的设施和力量来回应以色列，中东紧张局势持续。   ",
          Source: "市场资讯",
        },
      ],
    };
  },
  created() {
    // 获取倒计时
    getTime(this.form1).then((res) => {
      console.log("倒计时数据", res);
      this.curStartTime = res.Data;
    });
    this.updateCountdown();
    console.log(this.day, this.hour);
    setInterval(this.updateCountdown, 1000);
    // 讲师列表
    getTeacherList(this.form).then((res) => {
      this.teacherList = res.Data;

      console.log("讲师数据--------------", res.Data);
    });
    this.$emit("controlShow", true);
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem("craig")) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem("craig", window.location.origin);
      } else {
        sessionStorage.setItem("craig", this.$route.query.craig);
      }
    }
    if (this.$route.query.isApp) {
      this.isApp = true;
    }
    // 获取 活动 时间
    var data = {
      Platform: "1",
      ActivityId: "320",
      Account: undefined,
    };
    getYiXiTime(JSON.stringify(data)).then((res) => {
      this.activityTime.starttime = parseTime(
        new Date(res.content.starttime + 28800000),
        "yyyy-MM-dd hh:mm:ss"
      );
      this.activityTime.endtime = parseTime(
        new Date(res.content.endtime + 28800000),
        "yyyy-MM-dd hh:mm:ss"
      );
      this.activityTime.prizedeadline = parseTime(
        new Date(res.content.prizedeadline + 28800000),
        "yyyy-MM-dd hh:mm:ss"
      );
    });
  },
  computed: {
    ...mapState("common", {
      androidMt4DownloadUrl: "androidMt4DownloadUrl",
      androidDownloadUrl: "androidDownloadUrl",
      iosMt4DownloadUrl: "iosMt4DownloadUrl",
      iosMt5DownloadUrl: "iosMt5DownloadUrl",
    }),
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
     hoverLive() {
      this.showcont = true;
    },
    hoverLive1() {
      this.showcont1 = true;
    },
    hoverLive2() {
      this.showcont2 = true;
    },
    hoverLive3() {
      this.showcont = false;
    },
    hoverLive4() {
      this.showcont1 = false;
    },
    hoverLive5() {
      this.showcont2 = false;
    },
    scrollToElement() {
      const element = this.$refs.targetElement;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    // 下载app
    scrollToTarget(){
      const targetElement =this.$refs.targetElement;
      const targetPosition= targetElement.offsetTop;
      window.scrollTo({
         top:targetPosition,
         behavior:'smooth'
      })
    
    },
    updateCountdown() {
      const today = new Date();
      const endOfMonth = new Date(this.curStartTime).getTime();
      // const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      let timeLeft = endOfMonth - today;
      if (timeLeft <= 0) {
        timeLeft = 0;
        clearInterval(this.timer);
      }
      this.day = String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(
        2,
        "0"
      );
      this.hour = String(
        Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ).padStart(2, "0");
      this.min = String(
        Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0");
      this.second = String(
        Math.floor((timeLeft % (1000 * 60)) / 1000)
      ).padStart(2, "0");
    },
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem("craig");
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    getLive800() {
      window.open(this.$parent.live800UrlUc);
    },
    getClassRoom() {
      this.$router.push({
        name: "classRoom",
      });
    },
    openDownload() {
      window.open("https://oss.0790jiaxiao.com/HS/software/hs_9999.apk");
    },
    selectMore(){
      window.open('https://www.rlcproltd.net/#/Market')
    }
  },
};
</script>
<style lang="less" scoped>
.tabbar {
  width: 100%;
  background-color: #ffffff;

  img {
    margin: 10px 30px;
    width: 130px;
  }
}

.navBarFixed {
  position: fixed;
  top: 0;
  z-index: 999;
}

.yx_7525 {
  width: 100%;
  // background-color: #c1e3fc;
}

.step_1 {
  width: 100%;
  height: 472px;
  margin: 0 auto;
  box-sizing: border-box;
  background: url("/subject/yx_1105/wap/topbg.png") no-repeat center;
  background-size: 100% 100%;
  position: relative;
  z-index: 9;
  .slider {
    height: 97px;
    width: 100%;
  }
  .btn-time {
    width: 100%;
    position: absolute;
    bottom: -8%;

    font-size: 18px;

    text-align: center;
    color: #ffffff;
    letter-spacing: 1.8px;
    .day {
      position: absolute;
      left: 6.5%;
      bottom: 29%;
    }
    .hours {
      position: absolute;
      left: 21%;
      bottom: 29%;
    }
    .minute {
      position: absolute;
      left: 34%;
      bottom: 29%;
    }
    .second {
      position: absolute;
      left: 49%;
      bottom: 29%;
    }
  }
  .bgtit {
    background: url("/subject/yx_7525/wap/bgtit.png") no-repeat center;
    background-size: 100% 100%;
    width: 232px;
    height: 36px;
    position: absolute;
    bottom: 189px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 36px;
    color: #fff;
    text-align: center;
  }
  .bgtime {
    text-align: center;
    width: 232px;
    height: 36px;
    position: absolute;
    bottom: 140px;
    bottom: 49%;
    left: 56%;
    padding: 0 20px;
    box-sizing:content-box;
    transform: translateX(-50%);
    color: rgb(64, 94, 162);
  }
  .jytime {
    margin-top: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    white-space: nowrap;
  }
  .text {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
}

.w {
  width: 94%;
  margin: 0 auto;
}
.w1 {
  padding: 0 0.5rem;
  width: 96%;
  margin: 0 auto;
  background: url("/subject/yx_1105/wap/background.png") no-repeat center;
  background-size: 100% 100%;
}
.w2 {
  width: 100%;
  margin: 0 auto;
  background: url("/subject/yx_1105/wap/background2.png") no-repeat center;
  background-size: 100% 100%;
}
.w3 {
  width: 100%;
  margin: 0 auto;
  background: url("/subject/yx_1105/wap/background1.png") no-repeat center;
  background-size: 100% 100%;
  padding: 0 0.5rem;
}
.w4 {
  width: 100%;
  margin: 0 auto;
  background: url("/subject/yx_1105/wap/bottom.png") no-repeat center;
  background-size: 100% 100%;
  padding: 0 0.5rem;
}
.step-title {
  width: 219px;
  height: 28px;
  margin-top: 40px;
  margin-left: 2rem;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.select-title {
  height: 14px;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
}
.active {
  width: 30px;
  height: 2px;
  background: #db0000;
  margin: 10px 0;
}
.select-content {
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  line-height: 20px;
}
.step_2 {
  width: 100%;
  // height: 1010px;
  box-sizing: border-box;
  // background: linear-gradient(to top, #dae3ff, #ffffff);

  border-radius: 20px;
  margin-top: 20%;
  position: relative;
  z-index: 23;
  .teacher {
    width: 100%;
    height: 198px;
  }
  .teacher {
    width: 100%;
    height: 198px;
    // margin-top: 87px;
    .toHref {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      cursor: pointer;
      width: 100%;
      height: 198px;
    }
  }
  .teacherlist {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .background {
    height: 228px;
  }
  .title {
    height: 90px;
    box-sizing: border-box;
    text-align: center;
    padding-top: 22px;

    p {
      &:nth-child(1) {
        color: #334d95;
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 10px;
      }

      &:nth-child(2) {
        color: #334d95;
        font-size: 14px;
      }
    }
  }
  .text-content {
    width: 100%;
    height: 169px;

    position: absolute;
    left: 0;
    bottom: 0;
    .text-cont {
      margin-top: 8%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
    
    }
  }
  .text-content1 {
    width: 100%;
        height: 230px;
    overflow: auto;

    position: absolute;
    left: 0;
    bottom: 2px;
    opacity: 0.86;
background: linear-gradient(-30deg,#750000 0%, #fe2a00 100%);
    .text-cont {
      margin-top: 8%;
      padding: 1rem;
      display: flex;
      flex-direction: column;
    
    }
  }
  .box {
    width: 94%;
    box-sizing: border-box;
    margin: 0 auto;

    .item {
      margin-top: 16px;

      .question {
        color: #334d95;
        font-size: 18px;
      }

      .answer {
        width: 100%;
        box-sizing: border-box;
        padding: 13px 16px;
        color: #334d95;
        font-size: 14px;
        line-height: 20px;
        background-color: #dceaff;
        border: 1px solid #334d95;
        border-radius: 10px;
        margin-top: 12px;

        span {
          font-weight: 600;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 15px;

    .item {
      font-size: 14px;
      border-radius: 4px;
      background: linear-gradient(to top, #f9e7cd, #ffffff);
      padding: 4px 7px;
      color: #a5542e;
      margin: 5px 2px;

      &:nth-child(2n) {
        background: linear-gradient(to top, #c1cfff, #ffffff);
        color: #334d95;
      }
    }
  }

  .btnBox {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 25px;
    div {
      width: 130px;
      height: 35px;
      border-radius: 10px;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background: linear-gradient(to top, #c1cfff, #ffffff);
      color: #334d95;
      margin: 0 auto;
      margin-bottom: 10px;
      &:nth-child(2) {
        background: linear-gradient(to top, #416ade, #9fa9ff);
        color: #fff;
      }
    }
  }
}
.flex1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.step_3 {
  width: 100%;
  // height: 505px;
  box-sizing: border-box;
  // background: linear-gradient(to top, #dae3ff, #ffffff);
  border-radius: 20px;
  margin-top: 20px;
  padding-bottom: 40px;
  p {
    margin-bottom: 23px;
    color: #999;
  }
  .title {
    height: 90px;
    box-sizing: border-box;
    text-align: center;
    padding-top: 22px;

    p {
      &:nth-child(1) {
        background-image: -webkit-linear-gradient(top, #ff9e9e, #eb4141);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 10px;
      }

      &:nth-child(2) {
        color: #334d95;
        font-size: 16px;
        text-align: center;

        span {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .imgBox {
    width: 94%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    .flex1 {
      display: flex;
      align-items: center;
      img {
        width: 181px;
        height: 186px;
      }
    }
    img {
      width: 208px;
      height: 213px;
    }
  }

  .brandBox {
    width: 94%;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 20px;

    .item {
      width: 100px;
      height: 65px;
      text-align: center;
      border: 1px solid #a0aaff;
      font-size: 14px;
      background-color: #fff;
      color: #334d95;
      box-sizing: border-box;
      padding-top: 10px;
      border-radius: 10px;

      p {
        &:nth-child(1) {
          font-weight: 600;
          margin-bottom: 4px;
        }
      }
    }
  }

  .textBox {
    margin-top: 20px;
    text-align: center;
    color: #334d95;
    font-size: 14px;

    p {
      &:nth-child(1) {
        margin-bottom: 5px;
      }

      &:nth-child(3) {
        margin-top: -12px;
      }
    }

    img {
      height: 18px;
      display: inline-block;
      position: relative;
      left: -20px;
    }

    span {
      color: #ff5a3d;
      font-weight: 600;
      font-size: 24px;
    }
  }

  .btnBox {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    // margin-top: 25px;
    margin-bottom: 10px;

    img {
      width: 145px;
      height: 34.5px;
    }
    div {
      height: 35px;
      border-radius: 10px;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      // background: linear-gradient(to top, #c1cfff, #ffffff);
      color: #334d95;
      margin: 0 auto;
    }
  }
}
.commen-tit {
  font-size: 14px;

  font-weight: 700;
  text-align: left;
  color: #ffffff;
}
.commen-cont {
  font-size: 12px;

  text-align: left;
  color: #ffffff;
  line-height: 20px;
}
.commen-active {
  width: 30px;
  height: 2px;
  background: #db0000;
  margin: 10px 0;
}
.step_4 {
  width: 100%;
  padding-bottom: 30px;
  box-sizing: border-box;
  // background: linear-gradient(to top, #dae3ff, #ffffff);
  border-radius: 20px;
  margin-top: 20px;

  .text-box {
    margin:  0 1.5rem;
  //  width: 100%;
  width: 87%;
    height: 365px;
    background: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(51, 51, 51, 0.1);
    padding: 18px 12px;
    .top{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .flexs {
    }
    .titles {
      font-size: 14px;

      font-weight: 700;
    
      color: #333333;
    }
    .more {
      font-size: 9px;

      font-weight: 400;

      color: #db0000;
      cursor: pointer;
   
    }
  }
  .newflex {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 23px;
  }
  .newdate {
    display: flex;

    margin-bottom: 23px;
    img {
      width: 104px;
      height: 60px;
      margin-right: 14px;
    }
    .tits {
      width: 204px;

      font-size: 12px;

      text-align: left;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .times {
      margin-top: 16px;
      height: 10px;
      font-size: 12px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: right;
      color: #999999;
    }
  }
  .background3 {
    height: 228px;
    width: 100%;
  }
  .title {
    box-sizing: border-box;
    text-align: center;
  
    padding: 22px 2rem 0;
    p {
      span {
        font-weight: 700;
        text-align: left;
        color: #db0000;
      }
      font-size: 12px;

      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 20px;
    }
  }
  .tabs {
  
   
    padding: 2rem ;
    background: url("/subject/yx_1105/wap/bg1.png") no-repeat center;
    background-size: 100% 100%;
    margin: 0 1rem;
    overflow: auto;
    position: relative;
        width: 92%;
      
       
        
        box-sizing: border-box;
    table {
      
      // border: 1px solid #ccc;
      th,
      td {
        border-bottom: 1px solid #ccc; /* 表头和单元格边框 */
        padding: 8px;
        text-align: center;
      }
      .biaoti {
        th {
          font-size: 10px;
          color: #fff;
          font-weight: 600;
          white-space: nowrap;
          background: linear-gradient(-90deg, #f10000 0%, #ff3333 100%), #db0000;
        }
      }
      .bodys {
        .guoqi {
          display: flex;
          width: 180px;
          font-size: 10px;
          img {
            width: 16px;
            height: 12px;
          }
          .guojia {
            margin-left: 15px;
           
            text-align: left;
            font-size: 10px;
          }
        }
        .numbers {
          font-size: 10px;
        }
        .actives {
          color: #ff4242;
        }
        .times {
          font-size: 10px;
          color: #ccc;
          white-space: nowrap;
        }
      }
    }
  }

  .box {
    margin-top: 20px;

    .left {
      width: 94%;
      margin: 0 auto;

      .textBox {
        width: 100%;

        .item {
          width: 100%;
          height: 35px;
          border-radius: 10px;
          border: 1px solid #a0aaff;
          line-height: 35px;
          display: flex;
          justify-content: space-between;
          color: #334d95;
          margin-top: 10px;
          font-size: 12px;

          div {
            &:nth-child(1) {
              width: 100px;
              text-align: center;
              background-color: #dceaff;
              border-radius: 10px;
              border-right: 1px solid #a0aaff;
            }

            &:nth-child(2) {
              width: 450px;
              text-align: center;
            }
          }
        }
      }

      .bottom {
        width: 96%;
        margin: 0 auto;
        margin-top: 18px;
        text-align: center;

        p {
          font-size: 14px;
          color: #334d95;
          margin-bottom: 7px;

          &:nth-child(1) {
            font-size: 17px;
            font-weight: 600;
          }

          span {
            font-size: 17px;
            font-weight: 600;
            color: #ff5a3d;
          }
        }
      }
    }

    .right {
      width: 96%;
      margin: 0 auto;
      text-align: center;
      color: #334d95;

      p {
        font-size: 14px;
        margin-top: 4px;

        &:nth-child(1) {
          font-size: 17px;
          font-weight: 600;
          margin-bottom: 10px;
          margin-top: 15px;
        }
      }

      div {
        width: 100%;
        font-size: 14px;
        text-align: center;
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #a0aaff;
        box-sizing: border-box;
        line-height: 26px;
        padding: 15px 0;
      }
    }
  }

  .imgBox {
    text-align: center;
    margin-top: 15px;

    img {
      height: 18px;
      position: relative;
      left: -22px;
    }
  }

  .btnBox {
    margin: 0 1rem 0 1.5rem ;
    padding-bottom: 2rem;
    width: 88%;
    background: #FDF4EB;
    display: flex;
    align-items: center;
    justify-content: center;
    
    // margin: 0 auto;
  
    img {
      width: 145px;
      height: 34.5px;
    }
    div {
      width: 130px;
      height: 35px;
      border-radius: 10px;
      text-align: center;
      line-height: 35px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      // background: linear-gradient(to top, #c1cfff, #ffffff);
      color: #334d95;
      margin: 0 auto;
      &:nth-child(2) {
        // background: linear-gradient(to top, #416ade, #9fa9ff);
        color: #fff;
      }
    }
  }
}

.step_5 {
  width: 100%;
  height: 1099.5px;
  box-sizing: border-box;
  // background: linear-gradient(to top, #dae3ff, #ffffff);
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  .tit {
    margin-bottom: 23px;
    height: 53.5px;
    
  }
  .title {
    height: 145px;
    box-sizing: border-box;
    text-align: center;
    padding-top: 10px;

    p {
      &:nth-child(1) {
        color: #334d95;
        font-weight: 600;
        font-size: 48px;
        margin-bottom: 20px;
      }

      &:nth-child(2) {
        color: #334d95;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }

  .box {
    margin-top: 30px;

    .right {
      text-align: center;
      .textBox {
        font-size: 14px;
        color: #fff;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 20px;
      }
      .appCode {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        .codeBody {
          color: #fff;
          > div:first-child {
            font-weight: 700;
            margin-bottom: 12px;
          }
          .codeBox {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            .codeItem {
              &:first-child {
                margin-right: 10px;
              }
              .canvasCode {
                border-radius: 6px;
              }
              div {
                line-height: 30px;

                background: linear-gradient(150deg, #ff2c41 0%, #ff1232 100%),
                  #ff3333;
                border-radius: 1px;
                color: #fff;
                border-radius: 4px;
                margin-top: 6px;
                font-size: 10px;
              }
            }
          }
        }
      }
      .serveBox {
        padding: 0 2rem;
        &-serve {
          color: red;
          border-bottom: 1px solid red;
          cursor: pointer;
        }
        &-item {
          
          display: flex;
          
          div {
            line-height: normal;
            font-size: 13px;
            color: #fff;
            text-align: left;
            // text-indent: 1.5em; /* 设置整体缩进 */
            // &:first-child {
            //   text-indent: 0;
            // }
          }
        }
      }

      .btnBox {
        width: 100%;
        margin: 0 auto;
        margin-top: 90px;

        div {
          width: 216px;
          height: 65px;
          border-radius: 10px;
          text-align: center;
          line-height: 65px;
          cursor: pointer;
          font-size: 24px;
          font-weight: 600;
          background: linear-gradient(to top, #c1cfff, #ffffff);
          color: #334d95;
          margin-bottom: 40px;

          &:nth-child(2) {
            background: linear-gradient(to top, #416ade, #9fa9ff);
            color: #fff;
          }
        }
      }
    }
  }
}

.dialog {
  position: relative;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 480px;
  box-shadow: 0px 0px 95px 0px rgba(187, 188, 192, 0.1);
  background-color: #fdf5e6;
  border-radius: 15px;
  overflow-y: scroll;
  z-index: 99999999;

  .closer {
    width: 23px;
    height: auto;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .dialogContent {
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;

    li {
      font-size: 14px;
      color: #a86e4f;
      line-height: 24px;

      span {
        font-weight: 600;
        color: #e34a3f;
      }

      img {
        width: 100%;
        height: auto;
        margin: 20px 0;
      }

      table {
        width: 100%;
        border: 1px solid #e34a3f;
        border-radius: 10px;

        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 24px;
            border-bottom: 1px solid #d2a765;
            border-right: 1px solid #d2a765;
            padding: 5px;
          }

          th:last-child {
            border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.dark {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
}
</style>